const sortOverrideMoviesByOrderAndAlphabetically = (a, b) => {
  if (a.customIndex > b.customIndex) {
    return 1;
  } else if (a.customIndex < b.customIndex) {
    return -1;
  }

  return a.movieTitle.localeCompare(b.movieTitle, "cs");
};

const transformOverrideMoviesData = (movies) => {
  return movies.map((orderRow) => ({
    movieId: orderRow?.movie?.[0]?.MovieId,
    customIndex: orderRow.customIndex,
    doNotShow: orderRow?.doNotShow,
    movieTitle: orderRow?.movie?.[0]?.TitleArray,
  }));
};

const setUniqueCustomIndexOrder = (movies) => {
  let previousIndex = -1;
  return movies.map((movie) => {
    if (movie.customIndex <= previousIndex) {
      previousIndex += 1;
      return { ...movie, customIndex: previousIndex };
    } else {
      previousIndex = movie.customIndex;
      return movie;
    }
  });
};

const implementMoviesWithOverriedOrder = (movies, orderOverrides) => {
  const moviesWithoutOverrides = movies.filter((movie) => {
    const movieId = movie.MovieId;
    return !orderOverrides.some((override) => override.movieId === movieId);
  });

  [...orderOverrides].forEach(({ movieId, customIndex, doNotShow }) => {
    const movie = movies.find(
      (m) => m.MovieId === movieId || m?.movieGroup?.[0]?.MovieId === movieId,
    );
    if (movie && !doNotShow) {
      moviesWithoutOverrides.splice(Number(customIndex), 0, {
        ...movie,
      });
    }
  });

  return moviesWithoutOverrides;
};

const sortMoviesByPremiereDate = (a, b, type = "asc") => {
  const aPremiere = a?.Premiere ? new Date(a?.Premiere) : new Date();
  aPremiere.setHours(0, 0, 0, 0);
  const bPremiere = b?.Premiere ? new Date(b?.Premiere) : new Date();
  bPremiere.setHours(0, 0, 0, 0);

  if (aPremiere.getTime() === bPremiere.getTime()) {
    return sortMoviesByTitle(a, b);
  }

  if (type === "asc") {
    return aPremiere.getTime() - bPremiere.getTime();
  }

  return bPremiere.getTime() - aPremiere.getTime();
};

const sortMoviesByTitle = (a, b) =>
  a.TitleArray.localeCompare(b.TitleArray, "cs");

export {
  sortOverrideMoviesByOrderAndAlphabetically,
  transformOverrideMoviesData,
  setUniqueCustomIndexOrder,
  implementMoviesWithOverriedOrder,
  sortMoviesByPremiereDate,
};
